import "./App.css";
import Auth from "./Auth";

function App() {
  return (
    <div className="page-bg min-h-screen">
      <div className="py-8">
        <div className="mx-auto max-w-3xl px-6 lg:px-8 text-center">
          <Auth/>
        </div>
      </div>
    </div>
  );
}

export default App;