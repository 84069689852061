import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { OidcProvider } from "./keycloakConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StrictMode>
    <OidcProvider
      fallback={<>Checking authentication ⌛️</>}
    >
      <App />
    </OidcProvider>
  </StrictMode>
);
